import { all, put, call } from 'redux-saga/effects'
import config from './../../config'

function getCookie(name){
    var re = new RegExp(name + "=([^;]+)");
    var value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
}

function delete_cookie(name){
	document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

function* getSessionJWT(){
	let auth = getCookie('jwtoken')
	console.log("COOKIE", auth)
	try{
		const response = yield fetch(`${config.server}/getUserData`,{
			credentials: 'include',
			method: 'GET',
			headers: {
				'Authorization': getCookie('jwtoken')
			}
		})
		const fetchData = yield response.json();
		console.log(fetchData)
		console.log(response.data);
		console.log(response.status);
		console.log(response.statusText);
		console.log(response.headers);
		console.log(response.config);
		if(fetchData.state == 'SUCCESS_LOGIN'){
			yield put({type: 'user/SET_LOGGED', data: true})
			yield put({type: 'user/SET_USER_DATA', data: fetchData.user_data})
			yield put({type: 'user/SET_CONFIRM_EMAIL', data: true})
		}else if(fetchData.state == 'SUCCESS_LOGIN_EMAIL_NOT_CONFIRM'){
			yield put({type: 'user/SET_LOGGED', data: true})
			yield put({type: 'user/SET_USER_DATA', data: fetchData.user_data})
			yield put({type: 'user/SET_CONFIRM_EMAIL', data: false})
		}else if(fetchData.state == 'INVALID_TOKEN'){
			yield put({type: 'user/SET_LOGGED', data: false})
			delete_cookie('jwtoken')
		}/* else{
			console.log("BOOM")
			yield put({type: 'user/SET_LOGGED', data: false})
		} */
	}catch(error){
		if (error.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
		  } else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			console.log(error.request);
		  } else {
			// Something happened in setting up the request that triggered an Error
			console.log('Error', error.message);
		  }
		  console.log(error.config);
	}

}

export default function* rootSaga() {
	yield all([
		getSessionJWT()
	])
}
