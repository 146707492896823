let server;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
	server = {
		server: 'http://localhost:3000',
		serverResponse: 'http://localhost:8000/responsePayU',
		assets: 'http://localhost:3000/assets',
		serverPayURegister: 'http://localhost:3000/payURequest',
		url_payu: 'https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/',
		merchanId_payu: '508029',
		apiKey_payu: '4Vj8eK4rloUd272L48hsrarnUA',
		accountId_payu: '512321',
		test_payu: '1'
	}
	
} else {
	server = {
		server: 'https://api.coworkentretodos.com',
		serverResponse: 'https://www.coworkentretodos.com/responsePayU',
		assets: 'https://api.coworkentretodos.com/assets',
		serverPayURegister: 'https://api.coworkentretodos.com/payURequest',
		url_payu: 'https://checkout.payulatam.com/ppp-web-gateway-payu/',
		merchanId_payu: '833959',
		apiKey_payu: 'U6qOaeBnjGeNgqCRtuhB4w3Z1z',
		accountId_payu: '841314',
		test_payu: '0'
	}

}

export default server
