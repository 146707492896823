import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga'
import sagas from './../sagas'
const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware]

const initState = {
	'isLogged' : false,
	'isConfirm' : false,
	'userData' : {},
	'promos': false
}

const filterState = {
	'filterByHeadQuarterAndPerson' : false,
	'filterByHeadQuarterAndPersonAndTypeOffice' : false,
	'filterByTypeOffice' : false,
	'filterByTypeOfficeAndHeadQuarter' : false
}

const user = (state=initState,action)=>{
	switch (action.type) {
		case "user/SET_USER_DATA":
			console.log("user/SET_USER_DATA",action.data)
			state.userData = action.data
			break
		case "user/SET_LOGGED":
			console.log("user/SET_LOGGED",action.data)
			state.isLogged = action.data
			break
		case "user/SET_CONFIRM_EMAIL":
			console.log("SET_CONFIRM_EMAIL",action.data)
			state.isConfirm = action.data
			break
		case "user/SET_PROMOS_CODE":
			console.log("SET_PROMOS_CODE",action.data)
			state.promos = action.data
			break
	}
    return state;
}

const filter = (state=filterState,action)=>{
	switch (action.type) {
		case "filter/SET_ACTUAL_TYPE_OFFICE":
			state.filterByTypeOffice = action.data
			break
		case "filter/SET_ACTUAL_TYPE_OFFICE_AND_HEAD_QUARTER":
			state.filterByTypeOfficeAndHeadQuarter = action.data
			break
		case "filter/SET_ACTUAL_FILTER_HEADQUARTER_AND_PERSON":
			state.filterByHeadQuarterAndPerson = action.data
			break
		case "filter/SET_ACTUAL_FILTER_HEADQUARTER_AND_PERSON_AND_TYPE_OFFICE":
			state.filterByHeadQuarterAndPersonAndTypeOffice = action.data
			break
	}
    return state;
}

let composeEnhancer;
if(typeof window !== 'undefined'){
	composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}else{
	composeEnhancer = compose
}

const finalStore = createStore(combineReducers({user,filter}), composeEnhancer(applyMiddleware(...middlewares)));
sagaMiddleware.run(sagas)

export default finalStore
